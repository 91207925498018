import { DynamicBlock } from '../common/dynamic-block';
import { changeStates } from '../common/address'

document.addEventListener("turbolinks:load", () => {
  // if there is only one format, add bottom border to fix accordion UI issue
  if ($('#addresses_accordion > .card').length == 2) {
    $('#addresses_accordion > .card:nth-child(0)').addClass('border-bottom')
  }

  $('#addresses_accordion').on('change', '[id$="_country"]', function() {
    changeStates(
      $(this).parents('.collapse').find('[id$="_state"]'),
      $(this).val(),
      () => {}
    )
  })

  new DynamicBlock('#addresses_accordion')
})
