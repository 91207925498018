/**
 * Add dynamic block in new/edit form
 * 1. Every block should have `blockClass` class
 * 2. Parent block should have .btn-add-block as sibling
 * 
 * Params
 * wrapper - id of parent element
 */
export class DynamicBlock {
  constructor(wrapper, blockClass='.card') {
    this.wrapper = $(wrapper)

    this.blockClass = blockClass
    this.count = this.wrapper.find(blockClass).length - 1
    this.limitedCount = this.wrapper.data('limited-count')

    this.templateRowHtml = $(this.wrapper.find('.template-block')).clone().removeClass('d-none template-block').prop('outerHTML')
    $(this.wrapper.find('.template-block')).remove() // Remove template element

    this.wrapper.siblings('.btn-add-block').on('click', this.onAdd.bind(this))
    this.wrapper.on('click', `${blockClass} .btn-remove`, this.onRemove.bind(this))
  }

  onAdd() {
    if (this.limitedCount && this.count >= this.limitedCount)
        return

    let newBlock = this.templateRowHtml
    // Remove index from template row field ][:id][:field_name] -> ][][:field_name]
    const index = this.count++
    newBlock = newBlock.replace(/\]\[\d+/g, `][${index}`)
                          .replace(/collapse_\d*/g, `collapse_${index}`)
    this.wrapper.append(newBlock)
  }

  onRemove(e) {
    const item = $(e.currentTarget).parent(this.blockClass)
    if (item.find('#destroy').length) {
      item.find('#destroy').prop('checked', true)
      item.addClass('d-none')
    } else {
      $(e.currentTarget).parent(this.blockClass).remove()
    }
  }
}
