let dataTable;

document.addEventListener("turbolinks:load", () => {
  if ($('#orders_table').length == 0)
    return

  dataTable = $('#orders_table').DataTable({
    processing: true,
    serverSide: true,
    stateSave: true,
    ajax: "/orders",
    autoWidth: false,
    columns: [
      { data: "id", render: function( data, type, row, meta ) {
        return `<a href="/orders/${row.id}">${row.id}</a>`
      } },
      { data: "created_at" },
      { data: "quantity" },
      { data: "total" },
      { data: "state" },
      { data: "tracking_number" },
      { data: "id", bSortable: false, className: 'text-center', render: function( data, type, row, meta ) {
        var html = `<a href="javascript:;" data-id="${row.id}" data-method="delete" class="text-danger"><i class="fa fa-times"></i></a>`
        return html
      } }
    ],
    columnDefs: [
      { orderable: true, targets: [0, 1] },
      { orderable: false, targets: '_all' }
    ],
    order: [[1, 'desc']]
  })

  $('#orders_table').on('click', '.text-danger', function() {
    if (!confirm('Are you sure?')) {
      return
    }
    const id = $(this).data('id')
    $.ajax(`/orders/${id}`, {
      dataType: 'json',
      method: 'DELETE'
    }).done(function() {
      dataTable.draw(false)
    })
  })
})

document.addEventListener("turbolinks:before-cache", () => {
  if (typeof dataTable != 'undefined')
    dataTable.destroy()
})
