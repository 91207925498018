import { showAlert } from '../common/alert'

document.addEventListener("turbolinks:load", () => {
  $('#deletion_request_modal .btn-primary').on('click', function() {
    $.ajax('/deletion_requests', {
      dataType: 'json',
      method: 'POST',
      data: {
        deletion_request: {
          email: $('#deletion_request_email').val(),
          message: $('#deletion_request_message').val()
        }
      }
    }).done(function() {
      $('#deletion_request_modal, .modal-backdrop').removeClass('show')
      $('#deletion_request_modal input, #deletion_request_modal textarea').val('')
      showAlert('Sent deletion request succesfully.', 'success')
    }).fail(function(errResult) {
      showAlert(errResult.responseJSON.error, 'danger')
    })
  })
})
